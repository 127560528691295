import React, { useEffect, useRef, useState, useCallback, memo } from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import { FaSearch } from "react-icons/fa";
import logo from "../image/cartbaglogo-removebg-preview.png";
import logo_courier_after_ship from '../image/couriers/after-ship.webp';
import logo_courier_dhl from '../image/couriers/dhl.webp';
import logo_courier_fedex from '../image/couriers/fedex.webp';
import logo_courier_tnt from '../image/couriers/tnt.webp';
import logo_courier_ups from '../image/couriers/ups.webp';
import logo_history from "../image/ph_ebay.webp";
import logo_message from '../image/ebay-chat.png';
import logo_paste from '../image/pasteIcon.png';
import logo_find from '../image/find.png';
import logo_sncisco from '../image/sncisco.png';
import logo_erp from '../image/erp.png';

import "./layout.css";
// import Button from '@mui/material/Button';
// import Box from '@mui/material/Box';
// import TextField from '@mui/material/TextField';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  ListItem,
  List,
  ListItemButton,
  ListItemText,
  IconButton,
  filledInputClasses,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from "@mui/material";
import WtbDialog from "./wtb-dialog/wtb-dialog";
import { debounce } from 'lodash';

const data_couriers = [
  {
    courierName: 'DHL',
    link: 'https://www.aftership.com/track/dhl/{{trackingNumber}}',
    image: logo_courier_dhl
  },
  {
    courierName: 'UPS',
    link: 'https://www.aftership.com/track/ups/{{trackingNumber}}',
    image: logo_courier_ups
  },
  {
    courierName: 'FedEx',
    link: 'https://www.aftership.com/track/fedex/{{trackingNumber}}',
    image: logo_courier_fedex
  },
  {
    courierName: 'TNT',
    link: 'https://www.aftership.com/track/tnt/{{trackingNumber}}',
    image: logo_courier_tnt
  },
  {
    courierName: 'Aftership',
    link: 'https://www.aftership.com/track/{{trackingNumber}}',
    image: logo_courier_after_ship
  },
]

const data_source_couriers = [
  {
    courierName: 'DHL(*)',
    link: 'https://www.mydhli.com/global-en/home/tracking/tracking-express.html?submit=1&tracking-id={{trackingNumber}}', // number1%20number2
    image: logo_courier_dhl
  },
  {
    courierName: 'UPS(*)',
    link: 'https://www.ups.com/track?track=yes&trackNums={{trackingNumber}}&loc=en_US&requester=ST/tracksummary', // number1%20number2
    image: logo_courier_ups
  },
  {
    courierName: 'FedEx(*)',
    link: 'https://www.fedex.com/wtrk/track/?trknbr={{trackingNumber}}', // number1,number2
    image: logo_courier_fedex,

    /**
     * 
     * @param {string} keyword
     */
    customTrackingFormat: (keyword) => {
      return keyword.split(/\s+/).join(',')
    }
  },
  {
    courierName: 'TNT(*)',
    link: 'https://www.tnt.com/express/en_au/site/shipping-tools/tracking.html?searchType=con&cons={{trackingNumber}}', // number1%20number2
    image: logo_courier_tnt
  }
]

const useData = [
  { name: "BB", isChecked: true },
  { name: "US", isChecked: true },
  { name: "AU", isChecked: true },
  { name: "UK", isChecked: true },
  { name: "GE", isChecked: true },
];

/**
 * @type {SxProps<Theme>[]} autoFillButtonStyle
 */
const autoFillButtonStyle = [{ background: '#ffffff', color: '#000000' }, { background: '#f2e030', color: '#000000' }, { background: '#04AA6D', color: '#EEEEEE' }];


/**
 * @type {SxProps<Theme>[]} historyButtonStyle
 */
const historyButtonStyle = [{ background: '#ffffff', margin: '1px' }, { background: '#3486eb', color: '#ffffff', margin: '1px' }];

class AutoFillData {
  /**
   * 
   * @param {string} text 
   * @param {number} checkState // 0: not checked, 1: checked, 2: current check
   */
  constructor(text, checkState) {
    this.id = Date.now() + '.' + (Math.floor(Math.random() * (999 - 100)) + 100);
    this.text = text;
    this.checkState = checkState;
  }
}

class AutoFillDataHistory {
  /**
   * 
   * @param {string} text 
   * @param {{cisco: string, news: string, radioValue: string}} setting
   * @param {number} checkState
   */
  constructor(text, setting, checkState = 0) {
    this.id = Date.now() + '.' + (Math.floor(Math.random() * (999 - 100)) + 100);
    this.text = text;
    this.date = Date.now();
    this.setting = setting;
    this.checkState = checkState;
  }
}

const ListOfHistory = memo(({ checkListHistory, onClickHistory }) => {

  const childList = {};

  const updateChild = useCallback((childIdList) => {
    for (let i = 0; i < childIdList.length; i++) {
      const updateFunction = childList[childIdList[i]];
      console.log('function update', updateFunction);
      if (updateFunction) {
        updateFunction();
      }
    }
  }, [childList]);

  return (
    <List sx={{ p: 0 }}>
      {
        checkListHistory.map((x, index) => {
          return (
            <ListItemOfHistory key={x.id} x={x} onClickHistory={onClickHistory} childList={childList} updateChild={updateChild}></ListItemOfHistory>
          )
        })
      }
    </List>
  );
});


function ListItemOfHistory({ x, childList, onClickHistory, updateChild }) {

  let [flagUpdate, setFlagUpdate] = useState(0);

  const renderComponent = useCallback(() => {
    setFlagUpdate(Date.now());
  }, []);

  useEffect(() => {
    childList[x.id] = renderComponent;
  }, [childList]);

  return (
    <ListItem
      onClick={(e) => { onClickHistory(x, updateChild); e.stopPropagation() }}
      disablePadding
      sx={historyButtonStyle[x.checkState]}
    >
      <ListItemButton sx={{ p: 0 }} >
        <ListItemText
          sx={{ p: 0, pl: 3 }}
          primary={x.text}
        />
      </ListItemButton>
    </ListItem>
  );
}

function ToolSearchEbay() {
  const [copy, setCopy] = useState(''); // paste in input
  const [news, setNews] = useState(''); // checkbox new

  // check list raw data
  const checkListRaw = useRef([]);
  // check list
  const [checkList, setCheckList] = useState([]);
  const currentAutoFillRow = useRef(null);
  // textarea
  const textareaRef = useRef(null);
  // history
  const [checkListHistory, setCheckListHistory] = useState([]);
  const currentHistoryRow = useRef(null);
  // cisco search
  const [ciscoSearch, setCiscoSearch] = useState('');
  // st1 - all Radio
  const [radioValue, setRadioValue] = useState('');
  // const [array, setArray] = useState([]);
  const searchInput = useRef(null);

  const [isOpenWTBSkypeGroup, setOpenWTBSkypeGroup] = useState(false);

  // last hover tooltip copy string
  const [lastHoverTooltipString, setLastHoverTooltipString] = useState('');

  const [data, setData] = useState([]);

  useEffect(() => {
    initData();
  }, []);

  const initData = async () => {
    setData(useData);
    await syncServerHistory();
  }

  const resetSetting = () => {
    setNews('');
    setCiscoSearch('');
    setRadioValue('');
  }

  const setSetting = (setting) => {
    setNews(setting.news);
    setCiscoSearch(setting.cisco);
    setRadioValue(setting.radioValue);
  }

  const debouncedSetValue = useCallback(
    debounce((newValue) => setCopy(newValue), 500),
    []
  );

  const handleChangeSearchInput = (e) => {
    debouncedSetValue(e.target.value);
  };

  // event checkbox
  const handleChange = (e) => {
    const { name, checked } = e.target;
    if (name === "allSelect") {
      let tempData = data.map((item) => {
        return { ...item, isChecked: checked };
      });
      setData(tempData);
    } else {
      let tempData = data.map((item) =>
        item.name === name ? { ...item, isChecked: checked } : item
      );
      setData(tempData);
    }
  };

  /**
   * 
   * @param {React.MouseEvent<HTMLDivElement, MouseEvent>} e 
   * @returns 
   */
  const ClickAllPage = async (e) => {
    if (e.target.tagName !== 'DIV') return;
    const rawText = await navigator.clipboard.readText();
    if (rawText === textareaRef.current.value) return;
    textareaRef.current.value = rawText;
    updateCheckList(rawText);
  };

  const clickPateFullLine = async (e) => {
    const rawText = await navigator.clipboard.readText();
    setCopy(rawText);
    searchInput.current.value = rawText;
  };

  // enter in search bar
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleClickBtnUSAU();
    }
  };

  // change radio
  const handleChange1stValue = (e) => {
    setRadioValue(e.target.value);
  }

  const handleUncheckRadioButton = (e) => {
    // clear radio button if recheck
    if (e.target.value === radioValue) {
      setRadioValue('');
      e.preventDefault();
    }
  }

  // checkbox new
  const handleOnChangeCheckBoxNew = (e) => {
    if (e.target.checked) {
      setNews(e.target.value);
    } else {
      setNews("");
    }
  };

  // checkbox new
  const handleOnChangeCheckBoxCisco = (e) => {
    if (e.target.checked) {
      setCiscoSearch(e.target.value);
    } else {
      setCiscoSearch('');
    }
  };

  const encodeKeywordForRequest = (keyword) => {
    return keyword.replace(/[\r\n\x0B\x0C\u0085\u2028\u2029]+/g, " ");
  }

  // update search string
  const createSearchString = (keyword) => {
    const finalKey = (keyword || searchInput.current.value).trim().replace(/\s+/g, ' ');
    return finalKey + (news.length > 0 ? (" " + news) : '') + (ciscoSearch.length > 0 ? (" " + ciscoSearch) : '');
  }


  /**
   * add "" for ebay search string
   * @param {string} searchString 
   * @param {'1st' | 'All'} [forceSearchValue] 
   * @returns 
   */
  const addMarkForEbaySearch = (searchString, forceSearchValue) => {
    let result = '';
    const searchArray = searchString.split(' ');
    const finalRadioValue = forceSearchValue ?? radioValue;
    if (finalRadioValue === '1st') {
      const first = searchArray.shift();
      result = `"${first}" ${searchArray.join(' ')}`.trim();
    }
    else if (finalRadioValue === 'All') {
      searchArray.forEach(x => {
        result += '"' + x + '" ';
      });
    }
    else {
      return searchString;
    }

    return result;
  }

  const goToAfterShip = async (data, keyword) => {
    const searchWord = keyword || searchInput.current.value;
    const link = data.link.replace('{{trackingNumber}}', searchWord);
    window.open(
      link,
      "_blank",
      "noopener,noreferrer"
    );
  }

  /**
   * 
   * @param {typeof data_source_couriers[number]} data 
   * @param {string} keyword 
   */
  const goToSourceCourierPage = async (data, keyword) => {
    const searchWord = (keyword || searchInput.current.value).trim().replace(/\s+/g, ' ');
    const formatedSearchWord = data.customTrackingFormat ? data.customTrackingFormat(searchWord) : searchWord;
    const link = data.link.replace('{{trackingNumber}}', formatedSearchWord);
    window.open(
      link,
      "_blank",
      "noopener,noreferrer"
    );
  }

  // event click icon search
  const handleClick = async () => {
    addHistory(searchInput.current.value, { cisco: ciscoSearch, news: news, radioValue: radioValue });
    resetSetting();
    const search = createSearchString();
    const searchForEbay = addMarkForEbaySearch(search);

    data.findLast((item) => {
      if (item.isChecked == true) {
        if (item.name == "GE") {
          window.open(
            `https://www.ebay.de/sch/i.html?_nkw=${searchForEbay}&_sop=15`,
            "_blank",
            "noopener,noreferrer"
          );
        }
        if (item.name == "UK") {
          window.open(
            `https://www.ebay.co.uk/sch/i.html?_nkw=${searchForEbay}&_sop=15`,
            "_blank",
            "noopener,noreferrer"
          );
        }
        if (item.name == "AU") {
          window.open(
            `https://www.ebay.com.au/sch/i.html?_nkw=${searchForEbay}&_sop=15`,
            "_blank",
            "noopener,noreferrer"
          );
        }
        if (item.name == "US") {
          window.open(
            `https://www.ebay.com/sch/i.html?_nkw=${searchForEbay}&_sop=15`,
            "_blank",
            "noopener,noreferrer"
          );
        }
        if (item.name == "BB") {
          window.open(
            `https://members.brokerbin.com/?loc=partkey&parts=${search}`,
            "_blank",
            "noopener,noreferrer"
          );
        }
      }
      return false;
    });
    //    window.open(url, '_blank', 'noopener,noreferrer');
    // STACK-T1-1M
    // https://www.ebay.com/sch/i.html?_nkw=STACK-T1-1M&_sop=15
    // https://www.ebay.com.au/sch/i.html?_nkw=STACK-T1-1M&_sop=15
    // https://www.ebay.co.uk/sch/i.html?_nkw=STACK-T1-1M&_sop=15
    // https://www.ebay.de/sch/i.html?_nkw=STACK-T1-1M&_sop=15

    // https://members.brokerbin.com/?loc=partkey&parts=STACK-T1-1M
  };

  const handleClickBtnUS = () => {
    const search = createSearchString();
    const searchForEbay = addMarkForEbaySearch(search);
    if (search != "") {
      window.open(
        `https://www.ebay.com/sch/i.html?_nkw=${searchForEbay}&_sop=15`,
        "_blank",
        "noopener,noreferrer"
      );
    }
    addHistory(searchInput.current.value, { cisco: ciscoSearch, news: news, radioValue: radioValue });
    resetSetting();
  };

  const handleClickBtnAU = () => {
    const search = createSearchString();
    const searchForEbay = addMarkForEbaySearch(search);
    if (search != "") {
      window.open(
        `https://www.ebay.com.au/sch/i.html?_nkw=${searchForEbay}&_sop=15`,
        "_blank",
        "noopener,noreferrer"
      );
    }
    addHistory(searchInput.current.value, { cisco: ciscoSearch, news: news, radioValue: radioValue });
    resetSetting();
  };

  const handleClickBtnUK = () => {
    const search = createSearchString();
    const searchForEbay = addMarkForEbaySearch(search);
    if (search != "") {
      window.open(
        `https://www.ebay.co.uk/sch/i.html?_nkw=${searchForEbay}&_sop=15`,
        "_blank",
        "noopener,noreferrer"
      );
    }
    addHistory(searchInput.current.value, { cisco: ciscoSearch, news: news, radioValue: radioValue });
    resetSetting();
  };

  const handleClickBtnBB = () => {
    const search = createSearchString();
    if (search != "") {
      window.open(
        `https://members.brokerbin.com/?loc=partkey&parts=${search}`,
        "_blank",
        "noopener,noreferrer"
      );
    }
    addHistory(searchInput.current.value, { cisco: ciscoSearch, news: news, radioValue: radioValue });
    resetSetting();
  };

  const handleClickBtnUSAU = () => {
    const search = createSearchString();
    const searchForEbay = addMarkForEbaySearch(search);
    if (search != "") {
      window.open(
        `https://www.ebay.com.au/sch/i.html?_nkw=${searchForEbay}&_sop=15`,
        "_blank",
        "noopener,noreferrer"
      );
      window.open(
        `https://www.ebay.com/sch/i.html?_nkw=${searchForEbay}&_sop=15`,
        "_blank",
        "noopener,noreferrer"
      );
    }
    addHistory(searchInput.current.value, { cisco: ciscoSearch, news: news, radioValue: radioValue });
    resetSetting();
  };
  const handleClickBtnBBUSAUUK = () => {
    const search = createSearchString();
    const searchForEbay = addMarkForEbaySearch(search);
    if (search != "") {
      window.open(
        `https://www.ebay.co.uk/sch/i.html?_nkw=${searchForEbay}&_sop=15`,
        "_blank",
        "noopener,noreferrer"
      );
      window.open(
        `https://www.ebay.com.au/sch/i.html?_nkw=${searchForEbay}&_sop=15`,
        "_blank",
        "noopener,noreferrer"
      );
      window.open(
        `https://www.ebay.com/sch/i.html?_nkw=${searchForEbay}&_sop=15`,
        "_blank",
        "noopener,noreferrer"
      );
      window.open(
        `https://members.brokerbin.com/?loc=partkey&parts=${search}`,
        "_blank",
        "noopener,noreferrer"
      );
    }
    addHistory(searchInput.current.value, { cisco: ciscoSearch, news: news, radioValue: radioValue });
    resetSetting();
  };

  /**
   * 
   * @param {*} allowList 
   * @param {*} searchKey 
   * @param {'1st' | 'All'} [forceSearchValue] 
   */
  const handleClickSearchListBtn = (allowList, searchKey, forceSearchValue) => {
    const search = createSearchString(searchKey);
    const searchForEbay = addMarkForEbaySearch(search, forceSearchValue);
    if (search !== "") {
      allowList.UK && window.open(
        `https://www.ebay.co.uk/sch/i.html?_nkw=${searchForEbay}&_sop=15`,
        "_blank",
        "noopener,noreferrer"
      );
      allowList.AU && window.open(
        `https://www.ebay.com.au/sch/i.html?_nkw=${searchForEbay}&_sop=15`,
        "_blank",
        "noopener,noreferrer"
      );
      allowList.US && window.open(
        `https://www.ebay.com/sch/i.html?_nkw=${searchForEbay}&_sop=15`,
        "_blank",
        "noopener,noreferrer"
      );
      allowList.BB && window.open(
        `https://members.brokerbin.com/?loc=partkey&parts=${search}`,
        "_blank",
        "noopener,noreferrer"
      );
      addHistory(searchKey || searchInput.current.value, { cisco: ciscoSearch, news: news, radioValue: radioValue });
      resetSetting();
    }
  };

  const saveData = async (finalResult) => {
    if (!(finalResult.length > 0)) {
      checkListRaw.current = [];
    }
    else {
      checkListRaw.current = [...checkListRaw.current.filter(x => x.historyID !== finalResult[0].historyID), ...finalResult];
    }
    await fetch(
      process.env.REACT_APP_PATH_SAVE,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ datajson: JSON.stringify(checkListRaw.current) })
      }
    );
  }

  const saveHistory = async (histories) => {
    await fetch(
      process.env.REACT_APP_PATH_HISTORY_SAVE,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ datajson: JSON.stringify(histories) })
      }
    );
  }

  const syncServerHistory = async () => {
    const data = await fetch(
      process.env.REACT_APP_PATH_HISTORY_GET,
      {
        method: 'GET'
      }
    );
    const fetchData = await data.json().catch(() => []);
    currentHistoryRow.current = fetchData.find(x => x.checkState === 1) || null;
    setCheckListHistory(fetchData);
    return fetchData;
  }

  const onClickTextarea = async (e) => {
    // get clipboard and set it for textarea
    const rawText = await navigator.clipboard.readText();
    if (checkListHistory.length > 0 && rawText === checkListHistory[0].text) return;
    e.target.value = rawText;
    updateCheckList(rawText);
  }

  const onClickHistory = useCallback(async (data, updateChild) => {
    const updateIdList = [currentHistoryRow?.current?.id, data?.id];
    if (currentHistoryRow.current) {
      if (currentHistoryRow.current === data) return;
      currentHistoryRow.current.checkState = 0;
    }
    data.checkState = 1;
    currentHistoryRow.current = data;
    textareaRef.current.value = data.text;
    const newCheckList = [new AutoFillData(data.text, 0)];
    setSetting(data.setting);
    checkListFillKeyword(newCheckList[0], true);
    setCheckList(newCheckList);
    updateChild(updateIdList);
  }, []);

  const updateCheckList = async (rawText) => {
    const newStringArray = rawText
      .split(/[\\\"'?+()%$#@!:,;\s\.]+/g)
      .filter(x => x !== '' && x.length > 2)
      .map(x => x.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
        .replace(/[đ]/gi, (char) => {
          if (char === char.toUpperCase()) {
            return 'D'
          }
          return 'd'
        })
      );
    const finalResult = [];
    const oldDataMap = {};

    for (const data of checkList) {
      oldDataMap[data.text] = data;
    }

    const noDuplicateMap = { /*...oldDataMap*/ };
    for (const keyword of newStringArray) {

      if (noDuplicateMap[keyword]) continue;

      const newData = new AutoFillData(keyword, 0);
      finalResult.push(
        newData
      );

      noDuplicateMap[keyword] = newData;

    }

    //finalResult.push(...checkList);

    // select first text
    if (finalResult.length > 0) checkListFillKeyword(finalResult[0], false);

    setCheckList(finalResult);
  };

  const checkListFillKeyword = (data) => {
    if (currentAutoFillRow.current !== null) {
      currentAutoFillRow.current.checkState = 1;
    }
    currentAutoFillRow.current = data;
    data.checkState = 2;
    setCopy(data.text);
    searchInput.current.value = data.text;
  }

  const addHistory = (text, setting) => {
    const history = new AutoFillDataHistory(text, setting);
    checkListHistory.unshift(history);
    setCheckListHistory([...checkListHistory]);

    if (currentHistoryRow.current) currentHistoryRow.current.checkState = 0;

    currentHistoryRow.current = null;

    saveHistory([...checkListHistory]);

    return history;
  }

  return (
    <div onClick={(e) => ClickAllPage(e)} className="main-all-page">
      <Grid container>
        <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'nowrap', pt: 1, pl: 1, position: 'fixed', height: '100%', width: '20%', minWidth: '100px', maxWidth: '400px' }}>
          <Box
            sx={{
              maxHeight: '40%',
              minHeight: '40%',
              flexGrow: 3,
              overflow: 'scroll',
              mb: 1,
              p: 0,
              border: 'rgb(231, 233, 235) solid 1px',
              overflowX: 'hidden',
              background: '#E7E9EB',
              boxSizing: 'border-box',
              borderRadius: '10px'
            }}
          >
            <List sx={{ p: 0 }}>
              {
                checkList.map((x, index) => {
                  return (
                    <ListItem
                      key={x.text}
                      onClick={(e) => { checkListFillKeyword(x); resetSetting(); e.stopPropagation(); }}
                      disablePadding
                      sx={autoFillButtonStyle[x.checkState]}
                    >
                      <ListItemButton sx={{ p: 0 }} >
                        <ListItemText
                          sx={{ p: 0, pl: 3 }}
                          primary={x.text}
                        />
                      </ListItemButton>
                    </ListItem>
                  )
                })
              }
            </List>
          </Box>

          <Box sx={{ pl: 0, mb: 1, flexGrow: 1, overflow: 'hidden', borderRadius: '10px', maxHeight: '20%', minHeight: '20%' }}>
            <textarea
              ref={textareaRef}
              placeholder={'Click to paste'}
              readOnly onClick={onClickTextarea}
              style={{
                background: '#E7E9EB',
                border: 'none',
                width: '100%',
                // minHeight: '100px',
                minHeight: '100%',
                padding: '20px',
                resize: 'none'
              }}
            ></textarea>
          </Box>

          <Box
            sx={{
              // pl: 1, mt: 0, mb: 1,
              paddingLeft: '101%',
              position: 'relative',
              height: '0px'
            }}>
            <Button
              variant="outlined"
              color="error"
              sx={{
                p: 0,
                minWidth: 'auto',
                marginRight: '100%',
                backgroundColor: 'white'
              }}
              onClick={
                (e) => {
                  e.stopPropagation();
                  setCheckList([]);
                  saveData([]);
                  setCheckListHistory([]);
                  saveHistory([]);
                }}>
              <DeleteIcon sx={{ p: 0 }} />
            </Button>
          </Box>

          <Box
            sx={{
              //maxHeight: '40%',
              // minHeight: '100px',
              overflow: 'scroll',
              p: 0,
              border: 'rgb(231, 233, 235) solid 1px',
              overflowX: 'hidden',
              background: '#E7E9EB',
              boxSizing: 'border-box',
              borderRadius: '10px',
              flexGrow: 3,
            }}
          >
            <ListOfHistory checkListHistory={checkListHistory} onClickHistory={onClickHistory}></ListOfHistory>
          </Box>

        </Box>
        <Grid item >
          <Box className="container" sx={{ marginLeft: { xs: '150px !important', md: '220px !important', lg: '280px !important', xl: '350px !important' } }}>
            <div className="row d-flex justify-content-center">
              <div className="col-md-10">
                <div className=" px-3 pt-1">

                  <div className="container ">
                    <div className="row d-flex justify-content-center">
                      <div className="container">

                        <div
                          className="row"
                          style={{
                            display: 'flex',
                            justifyContent: 'center'
                          }}
                        >
                          <div className="row g-1 px-4" style={{ width: 'fit-content' }}>
                            {
                              [
                                { link: 'https://int.ipsupply.com.au/erptools/001_search-vpn?search={text}', image: logo_erp, name: 'VPN SEARCH' },
                                { link: 'https://int.ipsupply.com.au/inventory/112_indexbyserial?search={text}', image: logo_erp, name: 'SN SEARCH' },
                                { link: 'https://int.ipsupply.com.au/ebaytools/chats-ebay-v2', image: logo_erp, name: 'EBAY CHAT' },
                                { link: 'https://sn.danielvu.com/?lists_sncheck={text}', image: logo_sncisco, name: 'SN CHECK' },
                                { link: 'https://int.ipsupply.com.au/ebaytools/new-items', image: logo_sncisco, name: 'NEW ITEM' }
                              ].map(element => {
                                return (
                                  <div className="col-sm" key={element.name}>
                                    <div className="card-inner px-1 d-flex flex-column align-items-center">
                                      <div className="text-center mg-text">
                                        <Button
                                          title={lastHoverTooltipString}
                                          onMouseEnter={
                                            async () => {
                                              setLastHoverTooltipString(
                                                await navigator.clipboard.readText()
                                              );
                                            }
                                          }
                                          variant="contained"
                                          sx={{
                                            height: 40,
                                            width: 'max-content',
                                            borderRadius: 4,
                                            color: "blue",
                                            backgroundColor: "white",
                                            "&:hover": {
                                              color: "white",
                                            },
                                          }}
                                          className="btn-cart"
                                          onMouseUp={async (e) => {
                                            e.preventDefault();
                                            let keyword = searchInput.current.value;
                                            if (e.button === 1) {
                                              keyword = encodeKeywordForRequest(await navigator.clipboard.readText());
                                              //setCopy(keyword);
                                              searchInput.current.value = keyword;
                                            }
                                            window.open(
                                              element.link.replace(
                                                '{text}',
                                                keyword
                                              ),
                                              "_blank",
                                              "noopener,noreferrer"
                                            );
                                          }}
                                          target="_blank"
                                          size="large"
                                        >
                                          <img src={element.image} className="icon-cart" />
                                          <span style={{ fontSize: '15px' }} className="ps-2 display-1">
                                            {element.name}
                                          </span>
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                )
                              })
                            }
                          </div>
                        </div>

                        <div
                          className="row"
                          style={{
                            display: 'flex',
                            justifyContent: 'center'
                          }}
                        >
                          <div className="row g-1 px-4" style={{ width: 'fit-content' }}>
                            {
                              [
                                { link: 'https://cart.payments.ebay.com', name: 'US' },
                                { link: 'https://cart.payments.ebay.com.au', name: 'AU' },
                                { link: 'https://cart.payments.ebay.co.uk', name: 'UK' },
                                { link: 'https://cart.payments.ebay.de', name: 'DE' }
                              ].map(element => {
                                return (
                                  <div className="col-md-3" key={element.name}>
                                    <div className="card-inner px-3 py-1 d-flex flex-column align-items-center">
                                      <div className="text-center mg-text">
                                        <Button
                                          title={lastHoverTooltipString}
                                          onMouseEnter={
                                            async () => {
                                              setLastHoverTooltipString(
                                                await navigator.clipboard.readText()
                                              );
                                            }
                                          }
                                          variant="contained"
                                          sx={{
                                            height: 40,
                                            width: 160,
                                            borderRadius: 4,
                                            color: "blue",
                                            backgroundColor: "white",
                                            "&:hover": {
                                              color: "white",
                                            },
                                          }}
                                          className="btn-cart"
                                          href={element.link}
                                          target="_blank"
                                          size="large"
                                        >
                                          <img src={logo} className="icon-cart" />
                                          <span style={{ fontSize: '15px' }} className="ps-2 display-1">
                                            {`Cart ` + element.name}
                                          </span>
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                )
                              })
                            }
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>

                  <div className="container ">
                    <div className="container">
                      <div className="row d-flex justify-content-center">
                        {
                          [
                            { link: 'https://www.ebay.com/mye/myebay/v2/purchase', name: 'US' },
                            { link: 'https://www.ebay.com.au/mye/myebay/v2/purchase', name: 'AU' },
                            { link: 'https://www.ebay.co.uk/mye/myebay/v2/purchase', name: 'UK' },
                            { link: 'https://www.ebay.de/mye/myebay/v2/purchase', name: 'DE' }
                          ].map(element => {
                            return (
                              <div key={element.name} className="text-center m-2 p-0" style={{ width: "160px" }}>
                                <Button
                                  title={lastHoverTooltipString}
                                  onMouseEnter={
                                    async () => {
                                      setLastHoverTooltipString(
                                        await navigator.clipboard.readText()
                                      );
                                    }
                                  }
                                  type="button"
                                  href={element.link}
                                  target="_blank"
                                  // className="btn btn-outline-primary btn-rounded waves-effect h-auto w-100"
                                  variant="outlined"
                                  rel="noopener noreferrer"
                                >
                                  <img src={logo_history} className="icon-cart" />
                                  <span className="ps-1">{`History` + element.name}</span>
                                </Button>
                              </div>
                            )
                          })
                        }
                      </div>
                    </div>
                  </div>

                  <div className="container ">
                    <div className="container">
                      <div className="row d-flex justify-content-center">
                        {
                          [
                            { name: 'US', link: 'https://mesg.ebay.com/mesgweb/ViewMessages/0' },
                            { name: 'AU', link: 'https://mesg.ebay.com.au/mesgweb/ViewMessages/0' },
                            { name: 'UK', link: 'https://mesg.ebay.co.uk/mesgweb/ViewMessages/0' },
                            { name: 'DE', link: 'https://mesg.ebay.de/mesgweb/ViewMessages/0' }
                          ].map(element => {
                            return (
                              <div key={element.name} className="text-center m-2 p-0" style={{ width: "180px" }}>
                                <Button
                                  title={lastHoverTooltipString}
                                  onMouseEnter={
                                    async () => {
                                      setLastHoverTooltipString(
                                        await navigator.clipboard.readText()
                                      );
                                    }
                                  }
                                  type="button"
                                  href={element.link}
                                  target="_blank"
                                  // className="btn btn-outline-primary btn-rounded waves-effect h-auto w-100"
                                  variant="outlined"
                                  rel="noopener noreferrer"
                                >
                                  <img src={logo_message} className="icon-cart" />
                                  <span className="ps-1">{'MESSAGE ' + element.name}</span>
                                </Button>
                              </div>
                            )
                          })
                        }
                      </div>
                    </div>
                  </div>

                  <div className=" d-flex justify-content-center px-1 ">
                    <div className="d-flex flex-column justify-content-center bd-highlight">

                      <div class="d-flex flex-row flex-nowrap gap-3">
                        <Button
                          type="button"
                          onClick={clickPateFullLine}
                          target="_blank"
                          // className="btn btn-outline-primary btn-rounded waves-effect h-auto w-100"
                          variant="outlined"
                          rel="noopener noreferrer"
                        >
                          <img src={logo_paste} className="icon-cart" />
                        </Button>
                        <TextField
                          type="text"
                          id="outlined-basic"
                          // variant="outlined"
                          placeholder="Search..."
                          className="search-input"
                          onKeyDown={(e) => handleKeyDown(e)}
                          inputRef={searchInput}
                          sx={{ minWidth: '800px' }}
                          //value={copy}
                          //onChange={(e) => setCopy(e.target.value)}
                          //onChange={handleChangeSearchInput}
                          required
                        // focused
                        />
                        <Button
                          variant="contained"
                          color="primary"
                          sx={{
                            height: 47,
                            borderRadius: 3,
                          }}
                          onClick={() => handleClick()}
                          type="button"
                          className="search-icon"
                        >
                          <FaSearch className="search-icon-item" />
                        </Button>
                      </div>

                      <div class="d-flex flex-row flex-nowrap justify-content-center">
                        <FormControlLabel
                          control={
                            <Checkbox
                              // defaultChecked
                              type="checkbox"
                              // className=""
                              name="allSelect"
                              checked={
                                data.filter((item) => item?.isChecked !== true)
                                  .length < 1
                              }
                              onChange={handleChange}
                            />
                          }
                          label="ALL"
                        />
                        {data.map((item, index) => {
                          return (
                            <FormControlLabel
                              key={index}
                              control={
                                <Checkbox
                                  // defaultChecked
                                  type="checkbox"
                                  // className="form-check-input"
                                  name={item.name}
                                  checked={item?.isChecked || false}
                                  onChange={handleChange}
                                />
                              }
                              label={item.name}
                            />
                          );
                        })}
                      </div>

                      <div class="d-flex flex-row flex-nowrap">
                        <RadioGroup
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          defaultValue=""
                          name="radio-buttons-group"
                          style={{
                            // width: '100%',
                            flexWrap: 'wrap',
                            flexDirection: 'row'
                          }}
                          onChange={handleChange1stValue}
                          value={radioValue}
                        >
                          <FormControlLabel value="1st" control={<Radio onClick={handleUncheckRadioButton} />} label="1st-””" />
                          <FormControlLabel
                            control={
                              <Checkbox
                                value="NEW"
                                onChange={(e) => handleOnChangeCheckBoxNew(e)}
                                checked={news !== ''}
                              />
                            }
                            label="NEW"
                          />
                          <FormControlLabel value="All" control={<Radio onClick={handleUncheckRadioButton} />} label="All-””" />
                          <FormControlLabel
                            control={
                              <Checkbox
                                value="CISCO"
                                onChange={(e) => handleOnChangeCheckBoxCisco(e)}
                                checked={ciscoSearch !== ''}
                              // defaultChecked
                              />
                            }
                            label="CISCO"
                          />
                        </RadioGroup>
                      </div>

                    </div>{" "}
                    &nbsp;
                  </div>

                  {/* <div className="row main-checkbox">
                    <div className="col-md-8">
                      <div className="main-checkbox-all ">
                        <FormControlLabel
                          control={
                            <Checkbox
                              // defaultChecked
                              type="checkbox"
                              // className=""
                              name="allSelect"
                              checked={
                                data.filter((item) => item?.isChecked !== true)
                                  .length < 1
                              }
                              onChange={handleChange}
                            />
                          }
                          label="ALL"
                        />
                        {data.map((item, index) => {
                          return (
                            <FormControlLabel
                              key={index}
                              control={
                                <Checkbox
                                  // defaultChecked
                                  type="checkbox"
                                  // className="form-check-input"
                                  name={item.name}
                                  checked={item?.isChecked || false}
                                  onChange={handleChange}
                                />
                              }
                              label={item.name}
                            />
                          );
                        })}
                      </div>
                    </div>
                  </div> */}


                </div>
              </div>
            </div>

            {/* <div className="row d-flex justify-content-center">
              <div className="col-8">
                <div className=" px-3 ">
                  <div class="d-flex justify-content-center">
                    <FormControlLabel
                      control={
                        <Checkbox
                          // defaultChecked
                          type="checkbox"
                          // className=""
                          name="allSelect"
                          checked={
                            data.filter((item) => item?.isChecked !== true)
                              .length < 1
                          }
                          onChange={handleChange}
                        />
                      }
                      label="ALL"
                    />
                    {data.map((item, index) => {
                      return (
                        <FormControlLabel
                          key={index}
                          control={
                            <Checkbox
                              // defaultChecked
                              type="checkbox"
                              // className="form-check-input"
                              name={item.name}
                              checked={item?.isChecked || false}
                              onChange={handleChange}
                            />
                          }
                          label={item.name}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>

            <div className="row d-flex justify-content-center">
              <div className="col-8">
                <div className=" px-3 ">
                  <div class="d-flex justify-content-center">
                    <RadioGroup
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      defaultValue=""
                      name="radio-buttons-group"
                      style={{
                        // width: '100%',
                        flexWrap: 'wrap',
                        flexDirection: 'row'
                      }}
                      onChange={handleChange1stValue}
                      value={radioValue}
                    >
                      <FormControlLabel value="1st" control={<Radio onClick={handleUncheckRadioButton} />} label="1st-””" />
                      <FormControlLabel
                        control={
                          <Checkbox
                            value="NEW"
                            onChange={(e) => handleOnChangeCheckBoxNew(e)}
                            checked={news !== ''}
                          />
                        }
                        label="NEW"
                      />
                      <FormControlLabel value="All" control={<Radio onClick={handleUncheckRadioButton} />} label="All-””" />
                      <FormControlLabel
                        control={
                          <Checkbox
                            value="CISCO"
                            onChange={(e) => handleOnChangeCheckBoxCisco(e)}
                            checked={ciscoSearch !== ''}
                          // defaultChecked
                          />
                        }
                        label="CISCO"
                      />
                    </RadioGroup>
                  </div>
                </div>
              </div>
            </div> */}

            <div className="row d-flex justify-content-center">
              <div className="col-12">
                <div className=" px-0 ">
                  <div className="d-flex justify-content-center px-0 ">
                    {
                      [
                        {
                          bg: ["rgb(196 209 237)", "rgb(235, 212, 169)"],
                          allowList: { BB: false, AU: false, US: true, UK: false },
                          name: "US"
                        },
                        {
                          bg: ["rgb(245 172 196)", "rgb(235, 212, 169)"],
                          allowList: { BB: false, AU: true, US: false, UK: false },
                          name: "AU"
                        },
                        {
                          bg: ["rgb(244 172 245)", "rgb(235, 212, 169)"],
                          allowList: { BB: false, AU: true, US: true, UK: false },
                          name: "US, AU"
                        },
                        {
                          bg: ["rgb(141 182 233)", "rgb(235, 212, 169)"],
                          allowList: { BB: true, AU: false, US: false, UK: false },
                          name: "BB"
                        },
                        {
                          bg: ["rgb(141 182 233)", "rgb(235, 212, 169)"],
                          allowList: { BB: false, AU: false, US: false, UK: true },
                          name: "UK"
                        },
                        {
                          bg: ["rgb(233, 192, 115)", "rgb(235, 212, 169)"],
                          allowList: { BB: true, AU: true, US: true, UK: true },
                          name: "BB, US, AU, UK"
                        }
                      ].map(
                        element => (
                          <div className="text-center m-0 p-1" key={element.name}>
                            <Button
                              title={lastHoverTooltipString}
                              onMouseEnter={
                                async () => {
                                  setLastHoverTooltipString(
                                    await navigator.clipboard.readText()
                                  );
                                }
                              }
                              variant="contained"
                              color="primary"
                              sx={{
                                width: 150,
                                // height: 50,
                                borderRadius: 2,
                                color: "black",
                                paddingY: '8px',
                                backgroundColor: element.bg[0],
                                ":hover": {
                                  backgroundColor: element.bg[1],
                                },
                              }}
                              onMouseUp={
                                async (e) => {
                                  e.preventDefault();
                                  let keyword = searchInput.current.value;
                                  if (e.button === 1) {
                                    keyword = encodeKeywordForRequest(await navigator.clipboard.readText());
                                    //setCopy(keyword);
                                    searchInput.current.value = keyword;
                                  }
                                  handleClickSearchListBtn(
                                    element.allowList,
                                    keyword
                                  );
                                }
                              }
                              className="search-icon"
                            >
                              {element.name}
                            </Button>
                          </div>
                        )
                      )
                    }
                  </div>
                </div>
              </div>
            </div>

            <div className="row d-flex justify-content-center">
              <div className="col-12">
                <div className=" px-0 ">
                  <div className="d-flex justify-content-center px-0 ">
                    {
                      [
                        {
                          bg: ["rgb(196 209 237)", "rgb(235, 212, 169)"],
                          allowList: { BB: false, AU: false, US: true, UK: false },
                          name: "1st US"
                        },
                        {
                          bg: ["rgb(245 172 196)", "rgb(235, 212, 169)"],
                          allowList: { BB: false, AU: true, US: false, UK: false },
                          name: "1st AU"
                        },
                        {
                          bg: ["rgb(244 172 245)", "rgb(235, 212, 169)"],
                          allowList: { BB: false, AU: true, US: true, UK: false },
                          name: "1st US, AU"
                        },
                        {
                          bg: ["rgb(141 182 233)", "rgb(235, 212, 169)"],
                          allowList: { BB: true, AU: false, US: false, UK: false },
                          name: "1st BB"
                        },
                        {
                          bg: ["rgb(141 182 233)", "rgb(235, 212, 169)"],
                          allowList: { BB: false, AU: false, US: false, UK: true },
                          name: "1st UK"
                        },
                        {
                          bg: ["rgb(233, 192, 115)", "rgb(235, 212, 169)"],
                          allowList: { BB: true, AU: true, US: true, UK: true },
                          name: "1st BB, US, AU, UK"
                        }
                      ].map(
                        element => (
                          <div className="text-center m-0 p-1" key={element.name}>
                            <Button
                              title={lastHoverTooltipString}
                              onMouseEnter={
                                async () => {
                                  setLastHoverTooltipString(
                                    await navigator.clipboard.readText()
                                  );
                                }
                              }
                              variant="contained"
                              color="primary"
                              sx={{
                                width: 170,
                                // height: 50,
                                borderRadius: 2,
                                color: "black",
                                paddingY: '8px',
                                backgroundColor: element.bg[0],
                                ":hover": {
                                  backgroundColor: element.bg[1],
                                },
                              }}
                              onMouseUp={
                                async (e) => {
                                  e.preventDefault();
                                  let keyword = searchInput.current.value;
                                  if (e.button === 1) {
                                    keyword = encodeKeywordForRequest(await navigator.clipboard.readText());
                                    //setCopy(keyword);
                                    searchInput.current.value = keyword;
                                  }
                                  handleClickSearchListBtn(
                                    element.allowList,
                                    keyword,
                                    '1st'
                                  );
                                }
                              }
                              className="search-icon"
                            >
                              {element.name}
                            </Button>
                          </div>
                        )
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
          </Box >

          <Box className="row d-flex justify-content-center" sx={{ marginLeft: { xs: '150px !important', md: '220px !important', lg: '280px !important', xl: '350px !important' } }}>
            {
              data_couriers.map((x, index) => {
                return (
                  <div key={index} className="text-center m-2 p-0" style={{ width: 'fit-content', minWidth: '100px' }}>
                    <Button
                      type="button"
                      title={lastHoverTooltipString}
                      onMouseEnter={
                        async () => {
                          setLastHoverTooltipString(
                            await navigator.clipboard.readText()
                          );
                        }
                      }
                      onMouseUp={
                        async (e) => {
                          e.preventDefault();
                          let keyword = searchInput.current.value;
                          if (e.button === 1) {
                            keyword = encodeKeywordForRequest(await navigator.clipboard.readText());
                            //setCopy(keyword);
                            searchInput.current.value = keyword;
                          }
                          goToAfterShip(
                            x,
                            keyword
                          );
                        }
                      }
                      target="_blank"
                      className="h-auto w-100"
                      variant="outlined"
                      rel="noopener noreferrer"
                    >
                      <img src={x.image} className="icon-cart" />
                      <span className="ps-1">{x.courierName}</span>
                    </Button>
                  </div>
                )
              })
            }
          </Box>

          <Box className="row d-flex justify-content-center" sx={{ marginLeft: { xs: '150px !important', md: '220px !important', lg: '280px !important', xl: '350px !important' } }}>
            {
              data_source_couriers.map((x, index) => {
                return (
                  <div key={index} className="text-center m-2 p-0" style={{ width: 'fit-content', minWidth: '100px' }}>
                    <Button
                      type="button"
                      title={lastHoverTooltipString}
                      onMouseEnter={
                        async () => {
                          setLastHoverTooltipString(
                            await navigator.clipboard.readText()
                          );
                        }
                      }
                      onMouseUp={
                        async (e) => {
                          e.preventDefault();
                          let keyword = searchInput.current.value;
                          if (e.button === 1) {
                            keyword = encodeKeywordForRequest(await navigator.clipboard.readText());
                            //setCopy(keyword);
                            searchInput.current.value = keyword;
                          }
                          goToSourceCourierPage(
                            x,
                            keyword
                          );
                        }
                      }
                      target="_blank"
                      className="h-auto w-100"
                      variant="outlined"
                      rel="noopener noreferrer"
                    >
                      <img src={x.image} className="icon-cart" />
                      <span className="ps-1">{x.courierName}</span>
                    </Button>
                  </div>
                )
              })
            }
          </Box>


          <Box
            className="row d-flex flex-direction-row justify-cotent-left">
            <Box
              sx={{ marginLeft: { xs: '200px !important', md: '300px !important', lg: '320px !important', xl: '440px !important' } }}
              className="col-sm text-center m-4 p-0">
              <WtbDialog setOpenWTBSkypeGroup={setOpenWTBSkypeGroup}></WtbDialog>
            </Box>
          </Box>

        </Grid>
      </Grid>
    </div >
  );
}
export default ToolSearchEbay;
